import React, { useContext } from "react";
import { Link } from "gatsby";
import Button from "../../Button/Button";
import ModalContext from "../../context/ModalContext";
import { StaticImage } from "gatsby-plugin-image";
const SellValue = () => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);

  return (
    <>
      <div className="pt-[120px] pb-[60px] md:pt-[70px]">
        <div className="container">
          <div className="residentail_inner_items">
            <div className="images">
              <StaticImage src="../../../images/Sellpage2.jpg" alt="sellhome" />
            </div>
            <div className="flex justify-between items-start pt-[60px] sm:flex-col xs:pt-[40px]">
              <div className="max-w-[736px] w-full] sm:max-w-[100%]">
                <div>
                  <h3 className="font-IbarraRealNova text-d-30 font-700 pb-[24px]">
                    How does The Selby Team determine my home’s value?
                  </h3>

                  <div className="font-Quicksand text-d-3lg font-[500] text-[#666666]">
                    <p className="pb-[27px]">
                      Normal Heights is a great neighborhood in San Diego known for having distinct
                      character and a tight-knit community where people care about their neighbors.
                      Those are great selling points and as your realtor, we’ll focus on that and
                      hundreds of other factors, such as:
                    </p>
                    <ul className="list-disc pl-[17px] pb-[27px]">
                      <li>What other homes in Normal Heights have sold for</li>
                      <li>How desirable your neighborhood is</li>
                      <li>Unique features of your home</li>
                      <li>What makes the surrounding area appealing</li>
                    </ul>
                    <p className="pb-[27px]">
                      Then we go more in-depth, looking at factors such as your home’s condition and
                      any updates you’ve made that could add to its value.
                    </p>
                    <p className="pb-[64px]">
                      All of this is done by real estate professionals who use their knowledge and
                      expertise, plus up-to-date MLS data, to calculate a more accurate home value
                      than what you would get from an algorithm.
                    </p>
                  </div>
                </div>
              </div>

              <div className="border border-[#000] p-[32px] pb-[26px] max-w-[300px] w-full ml-[10px] md:p-[20px] md:pb-[20px] sm:max-w-[100%] sm:ml-[0px] sm:p-[32px] sm:pb-[26px] sm:mb-[60px]">
                <h3 className="font-IbarraRealNova text-d-6lg font-[700] pb-[16px]">
                  We’d Love to Meet You
                </h3>
                <p className="font-Quicksand text-d-3lg font-[500] pb-[16px] text-[#666666]">
                  Connect with a top-rated team of real estate professionals who listen, are readily
                  available, and will work tirelessly to help you sell your home in Normal Heights.
                </p>

                <Link to="tel:6192510979" className="xs:max-w-[48%] xs:w-full">
                  <Button
                    type="ghost"
                    color="black"
                    customStyle="py-[12px] w-full !justify-center boredr border-[#000] mb-[16px]"
                  >
                    <p className="w-full">619-251-0979</p>
                  </Button>
                </Link>

                <Link to="" className="w-full">
                  <Button
                    onClick={() => {
                      setModalvisible(true);
                      setPopupContent("steps");
                    }}
                    type="solid"
                    customStyle="py-[12px] w-full !justify-center"
                  >
                    <p className="w-full">Contact</p>
                  </Button>
                </Link>
              </div>
            </div>

            <div className="max-w-[736px] w-full] sm:max-w-[100%]">
              <h3 className="font-IbarraRealNova text-d-30 font-700 pb-[24px]">
                How is an estimate from The Selby Team different from online estimates, such as
                Zestimate®?
              </h3>

              <div className="font-Quicksand text-d-3lg font-[500] text-[#666666]">
                <p className="pb-[27px]">
                  Our home value reports are researched thoroughly (by humans) and backed by years
                  of experience and local expertise.
                </p>
                <p className="pb-[27px]">
                  Online estimates from Zillow, OpenDoor, Redfin, or any online home evaluator use
                  an algorithm that doesn’t match up to a comparative market analysis from a
                  knowledgeable real estate professional. Zillow even advises those who use their
                  online estimate to do further research.
                </p>
                <p className="pb-[27px]">
                  “We encourage buyers, sellers, and homeowners to supplement Zillow’s information
                  by doing other research such as getting a comparative market analysis (CMA) from a
                  real estate agent. - Zillow.com
                </p>
                <p className="pb-[24px]">
                  Getting an “instant” home value can be appealing but it doesn’t mean you’re
                  getting accurate information. There are so many other factors that can increase or
                  decrease a home’s value. And when something is as important as the value of your
                  home, accuracy matters.
                </p>
              </div>

              <Link to="" className="w-full">
                <Button
                  onClick={() => {
                    setPopupContent("homeValuation");
                    setModalvisible(true);
                  }}
                  type="solid"
                  customStyle="py-[12px] !justify-center"
                >
                  <p className="w-full">Get My VALUATION</p>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellValue;
